<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="" prop="image_url" width="100">
      <template slot-scope="scope">
        <el-row type="flex" align="middle">
          <el-col :lg="8" title="Private">
            <i
              v-if="scope.row.status && scope.row.status == 'private'"
              class="fas fa-lock"
            ></i>
          </el-col>
          <el-col :lg="16" class="avatar-img">
            <img
              :src="scope.row.image_url || imageDefaultUrl"
              alt="href"
            />
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Tên phân hệ" prop="name">
      <template slot-scope="scope">
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Trưởng phân hệ" prop="created_at">
      <template slot-scope="scope">
        <el-row type="flex" align="middle">
          <img
            class="mr-2"
            :src="scope.row.based_model && scope.row.based_model.avatar && scope.row.based_model.avatar.url ? scope.row.based_model.avatar.url : imageDefaultUrl"
            alt="href"
          />
          <router-link v-if="!!scope.row.based_model.name" class="text-primary" :to="getToShowRouter(scope.row.based_model)">{{ scope.row.based_model.name }}</router-link>
          <span v-else>Không tồn tại</span>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Ngày khởi tạo" prop="created_at">
      <template slot-scope="scope">
        <span>{{ scope.row.created_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="200" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip v-if="!!scope.row.based_model.name" content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="handleUpdateCategory(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip v-if="!!scope.row.based_model.name" content="Chi tiết" placement="top">
          <el-button icon="el-icon-document" type="primary" circle size="small" @click="$router.push({ name: 'HierarchyDetail', params: { id: scope.row.id } })"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDeleteHierarchy(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteHierarchy } from '@/services/hierarchy'
import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'ListCategories',
  props: {
    data: Array,
    loading: Boolean,
    type: String
  },
  data () {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    getToShowRouter,
    handleDeleteHierarchy(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa phân hệ này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id
        }
        deleteHierarchy(params, this.type).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: `Xóa phân hệ thành công`
          })
          this.$emit('reload')
        })
      })
    },

    handleUpdateCategory(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-lock {
  color: #ff5252;
}
img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin: 5px 0;
  &.language {
    width: 28px;
    height: 28px;
    border-radius: 0px;
    margin: 0 5px;
    &.inactive {
      filter: grayscale(100%);
    }
  }
}
</style>
